var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-3 col-md-3 col-lg-3" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "훈련 시나리오 목록",
                    columns: _vm.grid.columns,
                    data: _vm.grid.data,
                    gridHeight: _vm.grid.height,
                    filtering: false,
                    isExcelDown: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "trainingScenarioDescNo",
                  },
                  on: { rowClick: _vm.rowClick },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  label: "시나리오 복사",
                                  icon: "save_alt",
                                },
                                on: { btnClicked: _vm.copyScenario },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-9" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                },
                [
                  _c(
                    "q-form",
                    { ref: "editForm" },
                    [
                      _c(
                        "c-card",
                        {
                          staticClass: "cardClassDetailForm",
                          attrs: {
                            title: "훈련 시나리오 상세",
                            topClass: "topcolor-blue",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "card-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _vm.editable
                                    ? _c("c-btn", {
                                        attrs: {
                                          label: "신규시나리오",
                                          icon: "add",
                                        },
                                        on: { btnClicked: _vm.addScenario },
                                      })
                                    : _vm._e(),
                                  _c("c-btn", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.editable &&
                                          _vm.traning.trainingScenarioNo &&
                                          !_vm.isRevision &&
                                          !_vm.disabled,
                                        expression:
                                          "(editable && traning.trainingScenarioNo && !isRevision) && !disabled",
                                      },
                                    ],
                                    attrs: {
                                      label: "개정",
                                      icon: "restart_alt",
                                    },
                                    on: { btnClicked: _vm.setRevision },
                                  }),
                                  _c("c-btn", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.editable &&
                                          _vm.traning.trainingScenarioNo &&
                                          _vm.isRevision &&
                                          !_vm.disabled,
                                        expression:
                                          "(editable && traning.trainingScenarioNo && isRevision) && !disabled",
                                      },
                                    ],
                                    attrs: {
                                      label: "개정취소",
                                      icon: "restart_alt",
                                    },
                                    on: { btnClicked: _vm.cancelRevision },
                                  }),
                                  !_vm.disabled && _vm.editable
                                    ? _c("c-btn", {
                                        attrs: {
                                          url: _vm.saveUrl,
                                          isSubmit: _vm.isSave,
                                          param: _vm.traning,
                                          mappingType: _vm.saveType,
                                          label: "(훈련시나리오 일괄)저장",
                                          icon: "save",
                                        },
                                        on: {
                                          beforeAction: _vm.saveScenario,
                                          btnCallback: _vm.saveCallback,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("template", { slot: "card-detail" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-9 col-lg-9" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    required: "",
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    label: "훈련시나리오명",
                                    name: "trainingScenarioName",
                                  },
                                  model: {
                                    value: _vm.traning.trainingScenarioName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "trainingScenarioName",
                                        $$v
                                      )
                                    },
                                    expression: "traning.trainingScenarioName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-6 col-md-3 col-lg-3" },
                              [
                                _c("c-radio", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    comboItems: _vm.useFlagItems,
                                    label: "사용여부",
                                    name: "useFlag",
                                  },
                                  model: {
                                    value: _vm.traning.useFlag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.traning, "useFlag", $$v)
                                    },
                                    expression: "traning.useFlag",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    required: "",
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    rows: 4,
                                    autogrow: false,
                                    label: "훈련내용요약",
                                    name: "trainingContentsSummary",
                                  },
                                  model: {
                                    value: _vm.traning.trainingContentsSummary,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "trainingContentsSummary",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "traning.trainingContentsSummary",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    rows: 4,
                                    autogrow: false,
                                    label: "훈련시나리오개요",
                                    name: "trainingContentsOutline",
                                  },
                                  model: {
                                    value: _vm.traning.trainingContentsOutline,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "trainingContentsOutline",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "traning.trainingContentsOutline",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    rows: 4,
                                    autogrow: false,
                                    label: "직접참가자 유의사항",
                                    name: "directlyNotice",
                                  },
                                  model: {
                                    value: _vm.traning.directlyNotice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "directlyNotice",
                                        $$v
                                      )
                                    },
                                    expression: "traning.directlyNotice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    rows: 4,
                                    autogrow: false,
                                    label: "간접참가자 유의사항",
                                    name: "indirectNotice",
                                  },
                                  model: {
                                    value: _vm.traning.indirectNotice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "indirectNotice",
                                        $$v
                                      )
                                    },
                                    expression: "traning.indirectNotice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    rows: 4,
                                    autogrow: false,
                                    label: "준비물",
                                    name: "materials",
                                  },
                                  model: {
                                    value: _vm.traning.materials,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.traning, "materials", $$v)
                                    },
                                    expression: "traning.materials",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    editable: _vm.editable,
                                    disabled: _vm.disabled,
                                    rows: 4,
                                    autogrow: false,
                                    label: "사전준비사항",
                                    name: "preRequisites",
                                  },
                                  model: {
                                    value: _vm.traning.preRequisites,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "preRequisites",
                                        $$v
                                      )
                                    },
                                    expression: "traning.preRequisites",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", {
                              staticClass: "col-sm-6 col-md-6 col-lg-6",
                            }),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-upload", {
                                  attrs: {
                                    editable: _vm.editable && !_vm.disabled,
                                    label: "피해반경",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                              [
                                _c("c-upload", {
                                  attrs: {
                                    editable: _vm.editable && !_vm.disabled,
                                    label: "비상대응훈련시나리오",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c(
                    "c-table",
                    {
                      ref: "table",
                      attrs: {
                        topBorderClass: "topcolor-blue",
                        title: "훈련 상세 목록",
                        columns: _vm.gridDetail.columns,
                        data: _vm.traning.emergencyScenarioDetailModelList,
                        gridHeight: _vm.gridDetail.height,
                        filtering: false,
                        isExcelDown: false,
                        usePaging: false,
                        columnSetting: false,
                        editable: _vm.editable && !_vm.disabled,
                        selection: "multiple",
                        rowKey: "trainingScenarioDescNo",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: {
                                      label: "추가",
                                      icon: "add_circle",
                                    },
                                    on: { btnClicked: _vm.addScenarioDetail },
                                  })
                                : _vm._e(),
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.traning.emergencyScenarioDetailModelList
                                .length > 0
                                ? _c("c-btn", {
                                    attrs: { label: "제외", icon: "remove" },
                                    on: {
                                      btnClicked: _vm.removeScenarioDetail,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _vm.traning.trainingScenarioNo
              ? _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "개정", topClass: "topcolor-orange" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    required: _vm.editable && _vm.isRevision,
                                    editable: _vm.editable && _vm.isRevision,
                                    label: "개정번호",
                                    name: "revisionNum",
                                  },
                                  model: {
                                    value: _vm.traning.revisionNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.traning, "revisionNum", $$v)
                                    },
                                    expression: "traning.revisionNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "개정일시",
                                    name: "regDtStr",
                                  },
                                  model: {
                                    value: _vm.traning.regDtStr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.traning, "regDtStr", $$v)
                                    },
                                    expression: "traning.regDtStr",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    editable: false,
                                    label: "개정자",
                                    name: "regUserName",
                                  },
                                  model: {
                                    value: _vm.traning.regUserName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.traning, "regUserName", $$v)
                                    },
                                    expression: "traning.regUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("c-textarea", {
                                  attrs: {
                                    required: _vm.editable && _vm.isRevision,
                                    editable: _vm.editable && _vm.isRevision,
                                    label: "개정사유",
                                    autogrow: false,
                                    rows: 4,
                                    name: "revisionContent",
                                  },
                                  model: {
                                    value: _vm.traning.revisionContent,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.traning,
                                        "revisionContent",
                                        $$v
                                      )
                                    },
                                    expression: "traning.revisionContent",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          staticStyle: { "margin-top": "-18px !important" },
                          attrs: { id: "revdiv" },
                        },
                        [
                          _c("c-table", {
                            ref: "revision",
                            attrs: {
                              title: "개정이력",
                              tableId: "revision",
                              topBorderClass: "topcolor-orange",
                              columns: _vm.gridrev.columns,
                              data: _vm.gridrev.data,
                              gridHeight: "210px",
                              columnSetting: false,
                              expandAll: false,
                              isFullScreen: false,
                              usePaging: false,
                              isExcelDown: false,
                              hideBottom: true,
                              filtering: false,
                            },
                            on: { rowClick: _vm.rowClickRev },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }